import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import GlobalTokens from '@sainsburys-tech/design-tokens/dist/global/json/tokens.json';
import ColourBlock from '@components/colour-block';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const GridList = makeShortcode("GridList");
const GridListItem = makeShortcode("GridListItem");
const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#colour-principles"
            }}>{`Colour principles`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#the-colour-system"
            }}>{`The colour system`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#brand-palette"
            }}>{`Brand palette`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#monochrome-palette"
            }}>{`Monochrome palette`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#interaction-palette"
            }}>{`Interaction palette`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#semantic-palette"
            }}>{`Semantic palette`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#colour-accessibility"
            }}>{`Colour accessibility`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "colour-principles",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#colour-principles",
        "aria-label": "colour principles permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Colour principles`}</h2>
    <GridList columns="3" mdxType="GridList">
  <GridListItem title="Accessible" titleElement="h3" border mdxType="GridListItem">
    We strive to make sure that every colour we use meets accessibility
    standards. And if it doesn’t, we suggest alternatives to use within the
    colour system.
  </GridListItem>
  <GridListItem title="Distinctive" titleElement="h3" border mdxType="GridListItem">
    Each brand has unique colours that form a key part of their visual identity.
  </GridListItem>
  <GridListItem title="Consistent" titleElement="h3" border mdxType="GridListItem">
    This features shared colours for the brands that can add consistency and
    clarity when using colour.
  </GridListItem>
    </GridList>
    <hr></hr>
    <h2 {...{
      "id": "the-colour-system",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-colour-system",
        "aria-label": "the colour system permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`The colour system`}</h2>
    <p>{`Each brand that uses the colour system has its own individual palettes. Each of these brand palettes consist of base colours and extended colours. The base colours refer to the distinctive brand colours, while the extended colours consist of all the useable tints and shades of those base colours.`}</p>
    <h3>{`Example of a base colour`}</h3>
    <GridList columns="2" mdxType="GridList">
  <ColourBlock fill="#1976D2" mdxType="ColourBlock">Global Base</ColourBlock>
    </GridList>
    <h3>{`Example of extended colours`}</h3>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#1976D2" mdxType="ColourBlock">Base</ColourBlock>
  <ColourBlock fill="#1669BA" mdxType="ColourBlock">Dark</ColourBlock>
  <ColourBlock fill="#E8F1FB" mdxType="ColourBlock">Lighter</ColourBlock>
    </GridList>
    <p>{`Start your designs using the base colours first, as these are the distinctive colours that users attribute to the brand. Once you’ve used the base colours in your designs, the extended colours come in handy for creating contrast, adding subtle backgrounds, highlighting various interactive states and much more.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "brand-palette",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#brand-palette",
        "aria-label": "brand palette permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Brand palette`}</h2>
    <p>{`The brand palette contains the colours often most associated with a particular brand. They are split into primary, secondary, and sometimes tertiary palettes. The primary brand palette should be used first and foremost, as it is the brand's most recognisable colours. The secondary and tertiary brand palettes should be used to further accent and distinguish the brand.`}</p>
    <h3>{`Primary brand palette`}</h3>
    <p>{`The primary brand palette features the core colours of the brand. It’s important to lead with the primary brand palette, especially when you’re introducing the brand for the first time.`}</p>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#1976D2" mdxType="ColourBlock">Base</ColourBlock>
  <ColourBlock fill="#135CA3" mdxType="ColourBlock">Dark</ColourBlock>
  <ColourBlock fill="#E8F1FB" mdxType="ColourBlock">Lighter</ColourBlock>
    </GridList>
    <h3>{`Secondary brand palette`}</h3>
    <p>{`The secondary brand palette features complimentary brand colours that are also associated by users with the brand. They should be used sparingly and in combination with the primary brand palette.`}</p>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#2E7D32" mdxType="ColourBlock">Base</ColourBlock>
  <ColourBlock fill="#246127" mdxType="ColourBlock">Dark</ColourBlock>
  <ColourBlock fill="#F2F2F2" mdxType="ColourBlock">Lighter</ColourBlock>
    </GridList>
    <hr></hr>
    <h2 {...{
      "id": "monochrome-palette",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#monochrome-palette",
        "aria-label": "monochrome palette permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Monochrome palette`}</h2>
    <p>{`The monochrome palette is a shared group of colours that is best used to provide contrast and neutrality to your designs. Use the monochrome palette for text contrast, as well as icons, backgrounds, key-lines and interactive states.`}</p>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#000000" mdxType="ColourBlock">Black</ColourBlock>
  <ColourBlock fill="#262626" mdxType="ColourBlock">Darker</ColourBlock>
  <ColourBlock fill="#404040" mdxType="ColourBlock">Dark</ColourBlock>
  <ColourBlock fill="#737373" mdxType="ColourBlock">Base</ColourBlock>
  <ColourBlock fill="#D8D8D8" mdxType="ColourBlock">Light</ColourBlock>
  <ColourBlock fill="#F2F2F2" mdxType="ColourBlock">Lighter</ColourBlock>
  <ColourBlock fill="#FFFFFF" mdxType="ColourBlock">White</ColourBlock>
    </GridList>
    <hr></hr>
    <h2 {...{
      "id": "interaction-palette",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#interaction-palette",
        "aria-label": "interaction palette permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Interaction palette`}</h2>
    <p>{`The following colour palettes represent the colours used in interaction states. They are taken from the brand and monochrome palettes, and are used to communicate the default, hover, pressed and disabled states within the brand's UI.`}</p>
    <h3>{`Primary interaction palette`}</h3>
    <p>{`These colours are used for interactions states of elements that use the brand colours as fills, such as primary buttons or active states.`}</p>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#1976D2" mdxType="ColourBlock">Default</ColourBlock>
  <ColourBlock fill="#2060A9" mdxType="ColourBlock">Hover</ColourBlock>
  <ColourBlock fill="#1C5075" mdxType="ColourBlock">Pressed</ColourBlock>
  <ColourBlock fill="#D8D8D8" mdxType="ColourBlock">Disabled</ColourBlock>
    </GridList>
    <h3>{`Secondary interaction palette`}</h3>
    <p>{`The secondary interaction colours are used for interactions on elements that use the brand colours as outlines, such as secondary buttons or unselected tags.`}</p>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#FFFFFF" mdxType="ColourBlock">Default</ColourBlock>
  <ColourBlock fill="#2060A9" opacity={10} fontColour="#000" mdxType="ColourBlock">
    Hover
  </ColourBlock>
  <ColourBlock fill="#1C5075" opacity={20} fontColour="#000" mdxType="ColourBlock">
    Pressed
  </ColourBlock>
  <ColourBlock fill="#D8D8D8" mdxType="ColourBlock">Disabled</ColourBlock>
    </GridList>
    <hr></hr>
    <h2 {...{
      "id": "semantic-palette",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#semantic-palette",
        "aria-label": "semantic palette permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Semantic palette`}</h2>
    <p>{`The semantic palette is a shared group of colours that help communicate key messages like errors, warnings, successes and understanding next steps. The consistent use of semantic colours keeps cognitive load low and makes for a unified and engaging user experience across our brands.`}</p>
    <h3>{`Error`}</h3>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#FBE6E6" mdxType="ColourBlock">Lighter</ColourBlock>
  <ColourBlock fill="#DD2E2E" mdxType="ColourBlock">Light</ColourBlock>
  <ColourBlock fill="#D50000" mdxType="ColourBlock">Base</ColourBlock>
  <ColourBlock fill="#BD0000" mdxType="ColourBlock">Dark</ColourBlock>
  <ColourBlock fill="#A50000" mdxType="ColourBlock">Darker</ColourBlock>
    </GridList>
    <h3>{`Info`}</h3>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#E8F1FB" mdxType="ColourBlock">Lighter</ColourBlock>
  <ColourBlock fill="#428FDA" mdxType="ColourBlock">Light</ColourBlock>
  <ColourBlock fill="#1976D2" mdxType="ColourBlock">Base</ColourBlock>
  <ColourBlock fill="#1669BA" mdxType="ColourBlock">Dark</ColourBlock>
  <ColourBlock fill="#135CA3" mdxType="ColourBlock">Darker</ColourBlock>
    </GridList>
    <h3>{`Success`}</h3>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#E6F4ED" mdxType="ColourBlock">Lighter</ColourBlock>
  <ColourBlock fill="#40A673" mdxType="ColourBlock">Light</ColourBlock>
  <ColourBlock fill="#2E7D32" mdxType="ColourBlock">Base</ColourBlock>
  <ColourBlock fill="#296F2C" mdxType="ColourBlock">Dark</ColourBlock>
  <ColourBlock fill="#246127" mdxType="ColourBlock">Darker</ColourBlock>
    </GridList>
    <h3>{`Warning`}</h3>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#FFFBE6" mdxType="ColourBlock">Lighter</ColourBlock>
  <ColourBlock fill="#FFDD2E" mdxType="ColourBlock">Light</ColourBlock>
  <ColourBlock fill="#FFD600" mdxType="ColourBlock">Base</ColourBlock>
  <ColourBlock fill="#E2BE00" fontColour="#000" mdxType="ColourBlock">
    Dark
  </ColourBlock>
  <ColourBlock fill="#C6A600" fontColour="#000" mdxType="ColourBlock">
    Darker
  </ColourBlock>
    </GridList>
    <hr></hr>
    <h2 {...{
      "id": "colour-accessibility",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#colour-accessibility",
        "aria-label": "colour accessibility permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Colour accessibility`}</h2>
    <p>{`To comply with the Web Content Accessibility Guidelines 2.1 AA standard contrast ratios, choose colours from the global palettes that have sufficient colour contrast to make text and UI controls as easy as possible to read and distinguish.`}</p>
    <h3>{`Monochrome palette accessibility`}</h3>
    <p>{`Use Monochrome Dark (#262626) or Monochrome Black (#000000) for body text.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <span style={{
          color: `${GlobalTokens.color.monochrome.dark}`
        }}>
      This body copy is using monochrome dark text.
    </span>
  </Do>
  <Dont mdxType="Dont">
    <span style={{
          color: `${GlobalTokens.color.monochrome.light}`
        }}>
      This body copy is using monochrome light text.
    </span>
  </Dont>
    </Guideline>
    <p>{`When using Monochrome White (#FFFFFF) or Monochrome Lighter (#F2F2F2) as a background colour, only use Monochrome Dark (#262626) or Monochrome Black (#000000) for text.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <p>Minimal contrast ratio: 4.5</p>
    <ColourBlock fill={GlobalTokens.color.monochrome.white} center hideValues={true} fontColour={GlobalTokens.color.monochrome.dark} mdxType="ColourBlock">
      Monochrome Dark Text
    </ColourBlock>
    <ColourBlock fill={GlobalTokens.color.monochrome.lighter} center hideValues={true} fontColour={GlobalTokens.color.monochrome.dark} mdxType="ColourBlock">
      Monochrome Dark Text
    </ColourBlock>
  </Do>
  <Dont mdxType="Dont">
    <p>Fail</p>
    <ColourBlock fill={GlobalTokens.color.monochrome.white} center hideValues={true} fontColour={GlobalTokens.color.monochrome.light} mdxType="ColourBlock">
      Monochrome Light Text
    </ColourBlock>
    <ColourBlock fill={GlobalTokens.color.monochrome.lighter} center hideValues={true} fontColour={GlobalTokens.color.monochrome.base} mdxType="ColourBlock">
      Monochrome Base Text
    </ColourBlock>
  </Dont>
    </Guideline>
    <p>{`When using Monochrome Dark (#262626) as a background colour, only use Monochrome White (#FFFFFF), Monochrome Lighter (#F2F2F2) or Monochrome Light (#D8D8D8) for text.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <p>Minimal contrast ratio: 4.5.1</p>
    <ColourBlock fill={GlobalTokens.color.monochrome.dark} center hideValues={true} fontColour={GlobalTokens.color.monochrome.light} mdxType="ColourBlock">
      Monochrome Light Text
    </ColourBlock>
  </Do>
  <Dont mdxType="Dont">
    <p>Fail</p>
    <ColourBlock fill={GlobalTokens.color.monochrome.dark} center hideValues={true} fontColour={GlobalTokens.color.monochrome.base} mdxType="ColourBlock">
      Monochrome Base Text
    </ColourBlock>
  </Dont>
    </Guideline>
    <h3>{`Semantic palette accessibility`}</h3>
    <p>{`When using Error Base (#D50000), Success Base (#2E7D32) or Information Base (#1976D2) as a background colour, only use Monochrome White (#FFFFFF) for text.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <p>Minimal contrast ratio: 4.5</p>
    <ColourBlock fill={GlobalTokens.color.semantic.error.base} center hideValues={true} fontColour={GlobalTokens.color.monochrome.white} mdxType="ColourBlock">
      Monochrome White Text
    </ColourBlock>
    <ColourBlock fill={GlobalTokens.color.semantic.success.base} center hideValues={true} fontColour={GlobalTokens.color.monochrome.white} mdxType="ColourBlock">
      Monochrome White Text
    </ColourBlock>
    <ColourBlock fill={GlobalTokens.color.semantic.info.base} center hideValues={true} fontColour={GlobalTokens.color.monochrome.white} mdxType="ColourBlock">
      Monochrome White Text
    </ColourBlock>
  </Do>
  <Dont mdxType="Dont">
    <p>Fail</p>
    <ColourBlock fill={GlobalTokens.color.semantic.error.base} center hideValues={true} fontColour={GlobalTokens.color.monochrome.light} mdxType="ColourBlock">
      Monochrome Light Text
    </ColourBlock>
    <ColourBlock fill={GlobalTokens.color.semantic.success.base} center hideValues={true} fontColour={GlobalTokens.color.monochrome.light} mdxType="ColourBlock">
      Monochrome Light Text
    </ColourBlock>
    <ColourBlock fill={GlobalTokens.color.semantic.info.base} center hideValues={true} fontColour={GlobalTokens.color.monochrome.light} mdxType="ColourBlock">
      Monochrome Light Text
    </ColourBlock>
  </Dont>
    </Guideline>
    <p>{`When using Warning Base (#FFD600) as a background colour, only use Monochrome Dark (#262626) or Monochrome Black (#000000) text.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <p>Minimal contrast ratio: 4.5.1</p>
    <ColourBlock fill={GlobalTokens.color.semantic.warning.base} cente hideValues={true} fontColour={GlobalTokens.color.monochrome.dark} mdxType="ColourBlock">
      Monochrome Dark Text
    </ColourBlock>
  </Do>
  <Dont mdxType="Dont">
    <p>Fail</p>
    <ColourBlock fill={GlobalTokens.color.semantic.warning.base} center hideValues={true} fontColour={GlobalTokens.color.monochrome.base} mdxType="ColourBlock">
      Monochrome Base Text
    </ColourBlock>
  </Dont>
    </Guideline>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We’re on hand to answer any questions you have or help you with your next
  project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      